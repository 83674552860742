<template>
    <div class="bit-xxs-12">
        <masonry :cols="{default: 3,  1023: 2, 767: 1}" :gutter="30">
            <div v-if="is_frontpage" class="masonry-item">
                <div class="masonry-item__content" v-scroll-reveal="{delay: 0}">
                    <h2 v-if="title" class="h2--normal text--white padding-xxs-t-none padding-top-half"><span v-html="title">title</span></h2>
                    <div v-if="intro" class="text--white padding-bottom-double padding-xxs-b-none" v-html="intro"></div>
                </div>
            </div>
            <div v-for="(item, key) in items" :key="key" class="masonry-item">
                <div class="masonry-item__content">
                    <article-card :article="item" v-scroll-reveal="{delay: key * 100}"></article-card>
                </div>
            </div>
        </masonry>
    </div>
</template>

<script>
    import ArticleCard from "./article-card";

    export default {
        name: 'MasonryLayout',
        components: {
            ArticleCard
        },
        props: {
            is_frontpage: {
                type: Boolean,
                default: false
            },
            title: {
                type: String
            },
            intro: {
                type: String
            },
            items: {
                type: Array,
                required: true,
            },
        },
    }
</script>
