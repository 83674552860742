<script>
    import CbIntroColumn from "./cb-intro_column";
    import CbTextImageColumn from "./cb-text_image_column";
    import CbFeaturedTextImageColumn from "./cb-featured_text_image_column";
    import CbTextTwoColumns from "./cb-text_two_columns";
    import CbImage from "./cb-image";
    import CbTextColumn from "./cb-text_column";

    export default {
        name: "cb",
        components: {
            CbIntroColumn,
            CbTextImageColumn,
            CbFeaturedTextImageColumn,
            CbTextTwoColumns,
            CbImage,
            CbTextColumn,
        },
        props: {
            blocks: {
                type: Array,
                default: [],
            }
        },
        data() {
            return {
                map: {
                    'cb-intro_column': CbIntroColumn,
                    'cb-text_image_column': CbTextImageColumn,
                    'cb-featured_text_image_column': CbFeaturedTextImageColumn,
                    'cb-text_two_columns': CbTextTwoColumns,
                    'cb-image': CbImage,
                    'cb-text_column': CbTextColumn,
                }
            }
        },
        render( createElement, context ) {
            if ( this.blocks && this.blocks.length > 0 ) {
                let els = [];
                for ( let i = 0; i < this.blocks.length; i++ ) {
                    // TODO dit zou netter kunnen zonder alle imports, alleen als je in createElement() als eerste param alleen de name van het component meegeeft dan krijg je de foutmelding "For recursive components, make sure to provide the "name" option."
                    els.push( createElement( this.map[ 'cb-' + this.blocks[ i ].acf_fc_layout ], {
                        props: {
                            block: this.blocks[ i ],
                        }
                    } ) );
                }

                return createElement( 'div', {
                    attrs: {
                        class: 'cb__wrapper',
                    }
                }, els );
            } else {
                return false;
            }
        },
    }
</script>

<style scoped>

</style>
