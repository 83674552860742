<template>
    <div :class="'cb cb--'+block.acf_fc_layout" class="block-padding" v-scroll-reveal>
        <div class="container">
            <div class="frame">
                <div class="bit-xxs-12 bit-sm-9 bit-sm-offset-1" :class="'cb--'+block.acf_fc_layout+'__bit'" >
                    <div :class="'cb--'+block.acf_fc_layout+'__inner'">
                        <div class="h3" v-html="block.content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "cb-intro_column",
        props: {
            block: {
                type: Object,
            }
        },

    }
</script>

<style scoped>

</style>
