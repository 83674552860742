<template>
    <div :class="'cb cb--'+block.acf_fc_layout" class="block-padding" v-scroll-reveal>
        <div class="container">
            <div class="frame frame--justify-xxs-center" v-if="block.title">
                <div class="bit-xxs-12 bit-sm-10 bit-md-7 padding-xxs-t-none">
                    <h2 class="h2--normal text-center--sm"><span v-html="block.title"></span></h2>
                </div>
            </div>
            <div class="frame">
                <div class="bit-xxs-11 bit-sm-offset-1 bit-sm-5 padding-xxs-y-none">
                    <div class="cb__text" v-if="block.content_left" v-html="block.content_left"></div>
                </div>
                <div class="bit-xxs-11 bit-sm-5 bit-sm-offset-0 padding-xxs-y-none">
                    <div class="cb__text" v-if="block.content_right" v-html="block.content_right"></div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "cb-text_two_columns",
        props: {
            block: {
                type: Object,
            }
        }
    }
</script>

<style scoped>

</style>
