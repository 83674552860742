<template>
    <div :class="'cb cb--'+block.acf_fc_layout" class="block-padding" v-scroll-reveal>
        <div class="container">
            <div class="frame frame--align-xxs-center">
                <div class="bit-xxs-11 bit-sm-5">
                    <div class="content-wrapper">
                        <h2 class="h2--normal cb__title" v-if="block.title"><span v-html="block.title"></span></h2>
                        <div v-if="block.content" v-html="block.content"></div>
                    </div>
                </div>
                <div class="bit-xxs-12 bit-sm-offset-1 bit-sm-6">
                    <figure v-if="block.image" class="image-wrapper responsivebackgroundimage__wrapper" data-resp-bg-img-wrapper :style="'background-image: url('+block.image.src+');'">
                        <span v-html="block.image.html"></span>
                    </figure>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "cb-text_image_column",
        props: {
            block: {
                type: Object,
            }
        },
    }
</script>

<style scoped>

</style>
