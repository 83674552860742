<template>
    <div v-if="frontpage.extra_fields">
        <SiteNotification/>
        <div class="banner banner--blue banner--home banner--bg-image banner--green-gradient" :style="{ backgroundImage: `linear-gradient(180deg, rgba(22,76,78,0.10) 0%, #164C4E 79%), url('${frontpage.extra_fields.banner.image.src}')` }">
            <Header background-color="transparent" position-type="absolute"/>
            <section class="banner__inner">
                <div class="container">
                    <div class="frame">
                        <div class="bit-xxs-12 bit-sm-7 bit-sm-offset-1 bit-md-6 bit-lg-5 banner__inner__bit">
                            <div class="block-negative-placement" v-scroll-reveal>
                                <h1 class="banner__title">
                                    <span v-if="frontpage.extra_fields.banner && frontpage.extra_fields.banner.title" v-html="frontpage.extra_fields.banner.title"></span>
                                </h1>
                                <div class="lead" v-if="frontpage.extra_fields.banner && frontpage.extra_fields.banner.subtitle">
                                    <span v-html="frontpage.extra_fields.banner.subtitle"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="banner__link-block" v-if="frontpage.extra_fields.banner.link">
                <app-link :to="frontpage.extra_fields.banner.link.url" class="button"><span v-html="frontpage.extra_fields.banner.link.title"></span></app-link>
            </section>
            <div class="banner__scroll-to">
                <div class="container">
                    <div class="frame">
                        <div class="bit-xxs-12 bit-sm-8 bit-sm-offset-1 padding-bottom-none">
                            <div class="block-negative-placement">
                                <a href="javascript:;" @click.prevent="scrollToMain" class="banner__arrow">Scroll</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-container" data-scroll-to-pointer>
            <div class="cb-block" v-if="frontpage.content_blocks">
                <cb :blocks="frontpage.content_blocks"></cb>
            </div>
            <div class="articles-container background--blue-darker">
                <div class="container">
                    <div class="frame">
                        <masonry-layout :is_frontpage="true" :title="frontpage.extra_fields.articles_title" :intro="frontpage.extra_fields.articles_intro" :items="frontpage.extra_fields.articles"></masonry-layout>
                    </div>
                </div>
            </div>
            <div class="frontpage-article-themes block-padding">
                <div class="container">
                    <div class="frame">
                        <div class="bit-xxs-12">
                            <h3 class="text-center--xxs text--blue-lighter" v-if="frontpage.extra_fields.article_themes_title" v-scroll-reveal>
                                <span v-html="frontpage.extra_fields.article_themes_title"></span>
                            </h3>
                        </div>
                    </div>
                    <div class="frame">
                        <div class="bit-xxs-12 bit-sm-offset-1 bit-sm-10 padding-top-none padding-bottom-none">
                            <div class="frontpage-article-themes__inner block-negative-placement">
                                <div class="frame">
                                    <div class="bit-xxs-12 bit-sm-6 bit-md-3" v-for="(article_theme, key) in frontpage.extra_fields.main_article_themes" v-scroll-reveal="{delay: key * 100}">
                                        <article-theme-card :article_theme="article_theme" :key="key"></article-theme-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="frontpage-quote block-padding" v-if="quote">
                <div class="container">
                    <div class="frame frame--align-sm-center">
                        <div class="bit-xxs-12 bit-sm-6" v-scroll-reveal>
                            <div class="frontpage-quote__header">
                                <div class="text--weight-bold" v-if="quote.team_member_name" v-html="quote.team_member_name"></div>
                                <div v-if="quote.team_member_function" v-html="quote.team_member_function"></div>
                            </div>
                            <div class="frontpage-quote__quote">
                                <div class="h2 h2--normal" v-if="quote.quote" v-html="quote.quote"></div>
                            </div>

                            <div class="frontpage-quote__link" v-if="quote.link && quote.link.hasOwnProperty('url')">
                                <app-link :to="quote.link.url" :target="quote.link.target" class="button button--green padding-left-none"><span v-html="quote.link.title"></span></app-link>
                            </div>
                        </div>

                        <div class="bit-xxs-12 bit-sm-5 bit-sm-offset-1" v-scroll-reveal="{delay: 200}">
                            <figure v-if="quote.team_member_image && quote.team_member_image.hasOwnProperty('src')" class="frontpage-quote__image responsivebackgroundimage__wrapper" data-resp-bg-img-wrapper
                                    :style="'background-image: url('+quote.team_member_image.src+');'">
                                <div class="frontpage-quote__image__main-image" v-html="quote.team_member_image.html"></div>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block-padding" v-if="frontpage.extra_fields.cta">
                <div class="cta-block">
                    <cta :cta="frontpage.extra_fields.cta"></cta>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import Cb from "../components/cb";
    import Cta from "../components/cta";
    import Header from '~/components/header.vue';
    import MasonryLayout from '~/components/masonry-layout.vue';
    import ArticleThemeCard from "../components/article-theme-card";
    import AppLink from "../components/app-link";
    import SiteNotification from "../components/site-notification";

    export default {
        name: 'FrontPage',
        components: {
            SiteNotification,
            AppLink,
            ArticleThemeCard,
            Header,
            MasonryLayout,
            Cb,
            Cta,
        },
        async fetch( { store, params, error } ) {
            const locale = store.app.i18n.locale;
            if ( !store.state.frontpage.data.find( d => d.locale === locale ) ) {
                const data = {
                    locale: store.app.i18n.locale,
                    params: params,
                };
                await store.dispatch( 'frontpage/get', data )
                .then( ( res ) => {
                    store.commit( 'general/setAlternateRoute', res.alternateRoute );
                } )
                .catch( ( e ) => {
                    console.error( e );
                    error( { statusCode: 404, message: 'Page not found' } );
                } );
            }
        },
        layout: 'no-header',
        head() {
            return {
                title: this.buildSeoTitle( this.frontpage.seo ),
                meta: this.buildSeoMetaData( this.frontpage.seo, this.$nuxtI18nSeo() ),
                link: this.buildSeoLinkData( this.frontpage.seo, this.$nuxtI18nSeo() ),
                script: [ this.buildSeoSchema( this.frontpage.seo ) ],
            }
        },
        data() {
            return {
                quote: {},
                randomQuoteNumber: 0,
            }
        },
        computed: {
            ...mapState( {
                frontpage: function ( state ) {
                    const object = state.frontpage.data.find( d => d.locale === this.$i18n.locale );

                    return object && object.data ? object.data : {};
                },
                translations: function ( state ) {
                    return state.general.general_settings.translations && state.general.general_settings.translations[ this.$i18n.locale ] ? state.general.general_settings.translations[ this.$i18n.locale ] : {};
                },
            } ),
        },
        mounted() {
            this.enableCookies();
            this.setRandomQuote();

            if ( process.client ) {
                const event = document.createEvent( 'Event' );
                event.initEvent( 'imagesLoaded', true, true );

                document.dispatchEvent( event );
            }
        },
        methods: {
            scrollToMain() {
                this.scroll( {
                    to: '[data-scroll-to-pointer]',
                    duration: 1200,
                } )
            },
            setRandomQuote() {
                if ( this.frontpage.extra_fields.quotes && this.frontpage.extra_fields.quotes.length > 0 ) {
                    const length           = this.frontpage.extra_fields.quotes.length;
                    this.randomQuoteNumber = Math.floor( Math.random() * length );

                    this.quote = this.frontpage.extra_fields.quotes[ this.randomQuoteNumber ];
                } else {
                    this.quote = this.frontpage.extra_fields.quote && this.frontpage.extra_fields.quote.quote && this.frontpage.extra_fields.quote.quote.length > 0 ? this.frontpage.extra_fields.quote : false;
                }
            }
        }
    }
</script>

<style>

</style>
