<template>
    <section v-if="cta" class="cta" :class="{'cta--bg-green-full-width': bg_green_full_width}" v-scroll-reveal>
        <div class="container">
            <div class="frame position--relative">
                <div class="bit-xxs-12 bit-sm-3 cta__z-index-image padding-right-none">
                    <figure class="cta__image responsivebackgroundimage__wrapper" data-resp-bg-img-wrapper v-if="cta.image" :style="'background-image: url('+cta.image.src+');'">
                        <span class="cta-page__image__main-image" v-html="cta.image.html"></span>
                        <a :href="cta.linkedin" target="_blank"><img class="cta__image__icon-linkedin" :src="require(`@/assets/images/icon_linkedin.svg`)"></a>
                    </figure>
                </div>
                <div class="bit-xxs-12 bit-sm-6 bit-sm-offset-2 cta__z-index-main padding-top-none padding-bottom-none">
                    <div class="cta__content block-negative-placement">
                        <h2 class="cta__title">{{cta.title}}</h2>
                        <div class="cta__description" v-html="cta.description"></div>
                        <div class="cta__buttons">
                            <a v-if="cta.telephone" :href=" `tel:${cta.telephone}` " class="button padding-left-none padding-xxs-b-none">{{cta.telephone}}</a>
                            <a v-if="cta.email" :href=" `mailto:${cta.email}`" class="button padding-xxs-l-none padding-xxs-t-none">{{cta.email}}</a>
                        </div>
                    </div>
                </div>
                <div class="cta__bg background--blue-light"></div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "cta",
        props: {
            bg_green_full_width: {
                type: Boolean,
                default: false,
            },
            cta: {
                type: Object,
                required: true,
            },
        },
    }
</script>

<style scoped>

</style>
