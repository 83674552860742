<template>
    <div class="block-padding">
    <div :class="'cb cb--'+block.acf_fc_layout"  v-scroll-reveal>
        <div :class="'cb cb--'+block.acf_fc_layout+'__inner'">
            <div class="container">
                <div class="frame">
                    <div class="bit-xxs-12 bit-sm-5 padding-none" :class="'cb cb--'+block.acf_fc_layout+'__image'">
                        <div :class="'cb cb--'+block.acf_fc_layout+'__image__inner'">
                            <figure v-if="block.image" class="image-wrapper responsivebackgroundimage__wrapper" data-resp-bg-img-wrapper :style="'background-image: url('+block.image.src+');'">
                                <span v-html="block.image.html"></span>
                            </figure>
                        </div>
                    </div>
                    <div class="bit-xxs-12 bit-sm-offset-1 bit-sm-5 padding-top-none padding-bottom-none">
                        <div :class="'cb cb--'+block.acf_fc_layout+'__content'">
                            <h2 class="h2--normal cb__title" v-if="block.title"><span v-html="block.title"></span></h2>
                            <div v-if="block.content" v-html="block.content"></div>
                            <nuxt-link class="button button--arrow padding-left-none" v-if="block.url" :to="block.url.url" :target="block.url.target"><span v-html="block.url.title"></span></nuxt-link>
                        </div>
                    </div>
                    <div :class="'cb cb--'+block.acf_fc_layout+'__bg'"></div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        name: "cb-featured_ext_image_column",
        props: {
            block: {
                type: Object,
            }
        },
    }
</script>

<style scoped>

</style>
