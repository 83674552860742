import { render, staticRenderFns } from "./cb-featured_text_image_column.vue?vue&type=template&id=628848ee&scoped=true&"
import script from "./cb-featured_text_image_column.vue?vue&type=script&lang=js&"
export * from "./cb-featured_text_image_column.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "628848ee",
  null
  
)

export default component.exports