<template>
    <article class="article-theme-card" :class="extended_card ? 'article-theme-card--extended' : ''">
        <nuxt-link :to="article_theme.link">
            <figure class="article-theme-card__image responsivebackgroundimage__wrapper" data-resp-bg-img-wrapper v-if="extended_card && article_theme.image" :style="'background-image: url(\'' + article_theme.image.src + '\');'">
                <span v-html="article_theme.image.html"></span>
            </figure>
        </nuxt-link>
        <section class="article-theme-card__content">
            <h3 class="h4 article-theme-card__title">
                <nuxt-link :to="article_theme.link"><span v-html="article_theme.title"></span></nuxt-link>
            </h3>
            <div class="article-theme-card__description" v-if="article_theme.excerpt" v-html="article_theme.excerpt"></div>
            <div v-if="extended_card && article_theme.sub_themes && article_theme.sub_themes.length > 0" class="article-theme-card__sub-themes">
                <div class="article-theme-card__sub-themes__title" v-html="article_theme.sub_themes_title"></div>
                <nuxt-link :key="i" :to="item.link" class="badge" v-for="(item, i) in article_theme.sub_themes"><span v-html="item.title"></span></nuxt-link>
            </div>
        </section>
        <footer class="article-theme-card__footer">
            <nuxt-link class="button button--green button--small" :to="article_theme.link">
                <img src="~/assets/images/icon-article_theme.svg" alt=""/>
                <span v-html="translations.read_all_articles"></span>
            </nuxt-link>
        </footer>
    </article>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: "article-theme-card",
        props: {
            article_theme: {
                type: Object,
            },
            extended_card: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            ...mapState( {
                translations: function ( state ) {
                    return state.general.general_settings.translations && state.general.general_settings.translations[ this.$i18n.locale ] ? state.general.general_settings.translations[ this.$i18n.locale ] : {};
                },
            } )
        }
    }
</script>


