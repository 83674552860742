<template>
    <div :class="'cb cb--'+block.acf_fc_layout" class="block-padding" v-scroll-reveal>
        <div class="container">
            <div class="frame">
                <div class="bit-xxs-12">
                    <div :class="'cb cb--'+block.acf_fc_layout+'__inner'" v-html="block.image.html" v-if="block.image"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "cb-image",
        props: {
            block: {
                type: Object,
            }
        }
    }
</script>

<style scoped>

</style>
