<template>
    <article class="article-card">
        <section class="article-card__content">
            <p class="article-card__title-sub">{{article.theme_top_level_name}}</p>
            <h2 class="article-card__title">
                <nuxt-link :to="article.link"><span v-html="article.title"></span></nuxt-link>
            </h2>
            <div class="article-card__description" v-if="article.card_description" v-html="article.card_description"></div>
        </section>
        <footer class="article-card__footer">
            <nuxt-link class="button button--green button--small" :to="article.link">
                <span class="article-card__icon" v-html="article.icon"></span>
                <span v-html="translations.read_more_label"></span>
            </nuxt-link>
        </footer>
    </article>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: "article-card",
        props: {
            article: {
                type: Object,
            }
        },
        computed: {
            ...mapState( {
                translations: function ( state ) {
                    return state.general.general_settings.translations && state.general.general_settings.translations[ this.$i18n.locale ] ? state.general.general_settings.translations[ this.$i18n.locale ] : {};
                },
            } ),
        }
    }
</script>
